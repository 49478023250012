import React, { MouseEvent } from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import InsightsSingleStyled from '@/components/styled/InsightsSingleStyled';
import { IContentfulBlogProps } from '@/types';
import helpers from '@/utils/helpers';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';
import EventsSingleStyled from '@/components/styled/EventsSingleStyled';
import Speaker from '@/app/blog/Speaker';

const Event = ({ data, override, overrideContent, location }) => (
  <EventsSingleStyled>
    {usePageMeta(data.contentfulBlogPost?.meta)}

    <div className="content single-article-page">
      <div className="container">
        <div className="page-title link-title">
          <Link className="link back" to="/insights">
            All insights
          </Link>
        </div>

        <div className="post-header">
          <Reveal className="date-container">
            {!!data.contentfulBlogPost.category?.[0] && (
              <p className="category">{data.contentfulBlogPost.category[0]}</p>
            )}
            <span className="separator" />
            <p className="date">
              {helpers.contentfulDate(data.contentfulBlogPost.createdAt)}
            </p>
          </Reveal>
          <Reveal className="main-details">
            <div className="title-container">
              <h1>{data.contentfulBlogPost.title}</h1>
              <p className="tagline">{data.contentfulBlogPost.subtitle}</p>
              {data.contentfulBlogPost?.registerLink && (
                <a href={data.contentfulBlogPost?.registerLink} className="btn">
                  {data.contentfulBlogPost?.registerLabel
                    ? data.contentfulBlogPost?.registerLabel
                    : `Register Now`}
                </a>
              )}
            </div>

            {!!data.contentfulBlogPost.heroVideo && (
              <div className="event-video">
                <video
                  preload="none"
                  autoPlay
                  muted
                  loop
                  playsInline
                  id="bg-video"
                >
                  {data.contentfulBlogPost.heroVideo.map((video) => (
                    <source
                      src={video.file.url}
                      type={video.file.contentType}
                    />
                  ))}
                </video>
              </div>
            )}

            {!data.contentfulBlogPost.heroVideo &&
              !!data.contentfulBlogPost.image && (
                <div className="image-container">
                  <GatsbyImage
                    image={getImage(
                      data.contentfulBlogPost.image?.gatsbyImageData,
                    )}
                    alt={data.contentfulBlogPost.title || ``}
                  />
                </div>
              )}
          </Reveal>
        </div>

        {!!data.contentfulBlogPost.eventDetails && (
          <Reveal className="event-details">
            {data.contentfulBlogPost.eventDetails?.map((eventItem) => (
              <div className="event-card">
                <h4>{eventItem.title}</h4>
                <ContentfulRawToComponent
                  raw={eventItem.content.raw}
                  options={{
                    renderNode: {
                      ...override.renderNode,
                      ...overrideContent.renderNode,
                    },
                  }}
                />
              </div>
            ))}
          </Reveal>
        )}

        {!!data.contentfulBlogPost.eventInfo &&
          data.contentfulBlogPost.eventInfo?.map((eventInfo) => (
            <Reveal className="event-block">
              <div className="event-info-column">
                {eventInfo.image ? (
                  <GatsbyImage
                    image={getImage(eventInfo.image?.gatsbyImageData)}
                    alt={eventInfo.title || ``}
                  />
                ) : (
                  <img src="https://placehold.co/600x400" alt="placeholder" />
                )}
              </div>
              <div className="event-info-column">
                <h3>{eventInfo.title}</h3>
                <ContentfulRawToComponent
                  raw={eventInfo.content.raw}
                  options={{
                    renderNode: {
                      ...override.renderNode,
                      ...overrideContent.renderNode,
                    },
                  }}
                />
              </div>
            </Reveal>
          ))}
      </div>

      {!!data.contentfulBlogPost.reserveCta && (
        <Reveal className="reserve" id="reserve">
          <div className="container">
            <div className="reserve-row">
              <div className="reserve-column">
                <div className="image-container">
                  <GatsbyImage
                    image={getImage(
                      data.contentfulBlogPost.reserveCta.image?.gatsbyImageData,
                    )}
                    alt={data.contentfulBlogPost.reserveCta.title || ``}
                  />
                </div>
              </div>
              <div className="reserve-column">
                <h5>{data.contentfulBlogPost.reserveCta.title}</h5>
                <ContentfulRawToComponent
                  raw={data.contentfulBlogPost.reserveCta.content.raw}
                  options={{
                    renderNode: {
                      ...override.renderNode,
                      ...overrideContent.renderNode,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </Reveal>
      )}
      {!!data.contentfulBlogPost.content && (
        <div className="speakers" id="speakers">
          <div className="container">
            <div className="row">
              <Reveal
                className={
                  data.contentfulBlogPost.speakerContents
                    ? `col-2 col-m-2`
                    : `no-accordion`
                }
              >
                {!!data.contentfulBlogPost.content && (
                  <div>
                    <ContentfulRawToComponent
                      raw={data.contentfulBlogPost.content.raw}
                      options={{
                        renderNode: {
                          ...override.renderNode,
                          ...overrideContent.renderNode,
                        },
                      }}
                    />
                  </div>
                )}
              </Reveal>
              {!!data.contentfulBlogPost.speakerContents && (
                <div className="col-2 col-m-2 flex-column">
                  <Accordion className="service-accordion">
                    {data.contentfulBlogPost.speakerContents?.map((speaker) => (
                      <AccordionItem
                        header={speaker.title}
                        initialEntered={
                          data.contentfulBlogPost.accordionExpanded ?? false
                        }
                      >
                        <div className="speaker-cards">
                          {speaker.speakerPhotos && (
                            <Speaker speaker={speaker.speakerPhotos} />
                          )}

                          <div className="speaker-content">
                            <ContentfulRawToComponent
                              raw={speaker.content.raw}
                              options={{
                                renderNode: {
                                  ...override.renderNode,
                                  ...overrideContent.renderNode,
                                },
                              }}
                            />
                          </div>
                        </div>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!!data.contentfulBlogPost?.location && (
        <Reveal className="location" id="location">
          <div className="container">
            <div className="location-row">
              <div className="location-column">
                <div className="location-content">
                  <h3>{data.contentfulBlogPost?.location.title}</h3>
                  <ContentfulRawToComponent
                    raw={data.contentfulBlogPost?.location.content.raw}
                    options={{
                      renderNode: {
                        ...override.renderNode,
                        ...overrideContent.renderNode,
                      },
                    }}
                  />
                </div>
              </div>
              <div className="location-column">
                <div className="image-container">
                  <GatsbyImage
                    image={getImage(
                      data.contentfulBlogPost?.location.image?.gatsbyImageData,
                    )}
                    alt={data.contentfulBlogPost?.location.title || ``}
                  />
                </div>
              </div>
            </div>
          </div>
        </Reveal>
      )}

      {!!data.contentfulBlogPost?.logos && (
        <Reveal className="sponsors">
          <div className="container">
            <h3>{data.contentfulBlogPost?.sponsorTitle}</h3>
            <div className="sponsor-row">
              {data.contentfulBlogPost?.logos?.map((logo) => (
                <div className="image-container">
                  <GatsbyImage
                    image={getImage(logo.image?.gatsbyImageData)}
                    alt={logo.title || ``}
                  />
                </div>
              ))}
            </div>
          </div>
        </Reveal>
      )}

      {!!data.contentfulBlogPost.introduction && (
        <div className="post-container">
          <div className="content">
            <ContentfulRawToComponent
              raw={data.contentfulBlogPost.introduction.raw}
              options={{
                renderNode: {
                  ...override.renderNode,
                  ...overrideContent.renderNode,
                },
              }}
            />
          </div>
        </div>
      )}

      <Reveal className="post-footer">
        <div className="container">
          <span className="share-text">Share this article</span>
          <div className="share">
            <LinkedinShareButton className="link linkedin" url={location.href}>
              LinkedIn
            </LinkedinShareButton>
            <TwitterShareButton className="link twitter" url={location.href}>
              Twitter
            </TwitterShareButton>
            <FacebookShareButton url={location.href} className="link facebook">
              Facebook
            </FacebookShareButton>
          </div>
        </div>
      </Reveal>
    </div>
  </EventsSingleStyled>
);

const CareersCta = () => (
  <div className="careers-cta content">
    <div>
      <h4>Curious about IONA?</h4>
      <p>
        Join our team of future-thinkers, strategic pathfinders and
        technological explorers.
      </p>
    </div>

    <div>
      <Link className="btn" to="/careers/">
        Careers
      </Link>
    </div>
  </div>
);

const Article = ({ data, override, overrideContent, location }) => (
  <InsightsSingleStyled>
    {usePageMeta(data.contentfulBlogPost?.meta)}
    <div className="content single-article-page">
      <div className="container">
        <div className="page-title link-title">
          <Link className="link back" to="/insights">
            All insights
          </Link>
        </div>

        <div className="post-header">
          <Reveal className="date-container">
            {!!data.contentfulBlogPost.category?.[0] && (
              <p className="category">{data.contentfulBlogPost.category[0]}</p>
            )}
            <span className="separator" />
            <p className="date">
              {helpers.contentfulDate(data.contentfulBlogPost.createdAt)}
            </p>
          </Reveal>
          <Reveal className="main-details">
            <div className="image-container">
              {!!data.contentfulBlogPost.image && (
                <GatsbyImage
                  image={getImage(
                    data.contentfulBlogPost.image?.gatsbyImageData,
                  )}
                  alt={data.contentfulBlogPost.title || ``}
                />
              )}
            </div>
            <div className="title-container">
              <h1>{data.contentfulBlogPost.title}</h1>
            </div>
          </Reveal>
        </div>

        <div className="post-container">
          {!!data.contentfulBlogPost.introduction && (
            <Reveal className="intro">
              <ContentfulRawToComponent
                raw={data.contentfulBlogPost.introduction.raw}
              />
            </Reveal>
          )}
          {!!data.contentfulBlogPost.introVideo && (
            <div className="intro-video">
              <video preload="none" controls playsInline>
                <source
                  src={data.contentfulBlogPost.introVideo.file.url}
                  type={data.contentfulBlogPost.introVideo.file.contentType}
                />
                <track kind="captions" />
              </video>
            </div>
          )}
          {!!data.contentfulBlogPost.content && (
            <div className="content">
              <ContentfulRawToComponent
                raw={data.contentfulBlogPost.content.raw}
                options={{
                  renderNode: {
                    ...override.renderNode,
                    ...overrideContent.renderNode,
                  },
                }}
              />
            </div>
          )}

          {data.contentfulBlogPost?.careersCta && <CareersCta />}

          {data.contentfulBlogPost?.whitepaper?.image?.image &&
            data.contentfulBlogPost?.whitepaper?.file?.file && (
              <Reveal className="whitepaper-container">
                <div className="whitepaper-image">
                  <div className="image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulBlogPost?.whitepaper?.image?.image
                          .gatsbyImageData,
                      )}
                      alt={data.contentfulBlogPost?.whitepaper?.title || ``}
                    />
                  </div>
                </div>

                <div className="whitepaper-button">
                  <div className="download-description">
                    <div>Want to know more?</div>
                    <div>Download our whitepaper.</div>
                  </div>
                  <Link
                    className="btn"
                    to={`/insights/${data.contentfulBlogPost.slug}/whitepaper/${data.contentfulBlogPost?.whitepaper.slug}`}
                  >
                    <div className="button-container">
                      DOWNLOAD THE WHITEPAPER
                    </div>
                  </Link>
                </div>
              </Reveal>
            )}

          {data.contentfulBlogPost.contactMessage &&
            data.contentfulBlogPost.author && (
              <div className="author-container">
                <Reveal className="description">
                  <ContentfulRawToComponent
                    raw={data.contentfulBlogPost.contactMessage.raw}
                    options={override}
                  />
                </Reveal>

                <Reveal className="author-details">
                  <div className="image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulBlogPost.author.photo.gatsbyImageData,
                      )}
                      alt={`${data.contentfulBlogPost.author.firstName} ${data.contentfulBlogPost.author.lastName} Photo`}
                    />
                  </div>

                  <div className="about-author">
                    <p className="name">
                      {data.contentfulBlogPost.author.firstName}
                      {` `}
                      {data.contentfulBlogPost.author.lastName}
                    </p>
                    <p className="position">
                      {data.contentfulBlogPost.author.designation}
                    </p>

                    <a
                      className="email"
                      href={`mailto:${data.contentfulBlogPost.author.emailAddress}`}
                    >
                      {data.contentfulBlogPost.author.emailAddress}
                    </a>
                  </div>
                </Reveal>
              </div>
            )}

          {data.contentfulBlogPost?.bookIntroCta &&
            data.contentfulBlogPost?.author && (
              <div className="author-container">
                <Reveal className="author-details">
                  <div className="image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulBlogPost?.author?.photo?.gatsbyImageData,
                      )}
                      alt={`${data.contentfulBlogPost?.author?.firstName} ${data.contentfulBlogPost?.author?.lastName} Photo`}
                    />
                  </div>

                  <div className="about-author">
                    <p className="name">
                      {data.contentfulBlogPost?.author?.firstName}
                      {` `}
                      {data.contentfulBlogPost?.author?.lastName}
                    </p>
                    <p className="position">
                      {data.contentfulBlogPost?.author?.designation}
                    </p>

                    <a
                      className="email"
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${data.contentfulBlogPost?.author?.emailAddress}`}
                    >
                      {data.contentfulBlogPost?.author?.emailAddress}
                    </a>
                  </div>
                </Reveal>

                {data.contentfulBlogPost?.author?.isActive && (
                  <Reveal className="book-intro">
                    <Link
                      className="btn"
                      to={data.contentfulBlogPost?.author?.calendlyUrl}
                    >
                      {data.contentfulBlogPost?.author?.bookingButtonText ||
                        `Book a 30 min intro`}
                    </Link>
                  </Reveal>
                )}
              </div>
            )}

          <Reveal className="post-footer">
            <span className="share-text">Share this article</span>
            <div className="share">
              <LinkedinShareButton
                className="link linkedin"
                url={location.href}
              >
                LinkedIn
              </LinkedinShareButton>
              <TwitterShareButton className="link twitter" url={location.href}>
                Twitter
              </TwitterShareButton>
              <FacebookShareButton
                url={location.href}
                className="link facebook"
              >
                Facebook
              </FacebookShareButton>
            </div>

            <div className="link-container">
              <Link className="link back" to="/insights">
                All insights
              </Link>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  </InsightsSingleStyled>
);

const ContentfulBlog: React.FC<IContentfulBlogProps> = ({ data, location }) => {
  const richTextImages = {};
  const imagesList = data.contentfulBlogPost.content?.references || [];
  if (imagesList.length) {
    data.contentfulBlogPost.content?.references.map((image) => {
      const { contentfulId } = image;
      richTextImages[contentfulId] = {
        image: image.gatsbyImageData,
        description: image.description,
        alt: image.title,
      };
      return image;
    });
  }

  const override = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, _next) => {
        const { data: payload } = node;
        const dataImages = richTextImages;
        const src = dataImages[payload.target.sys.id];
        return (
          <div className="image-container">
            <GatsbyImage image={src?.image || ``} alt={src?.alt || ``} />
            {src?.description !== `` && (
              <>
                <span
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{ __html: src?.description }}
                />
              </>
            )}
          </div>
        );
      },
      // eslint-disable-next-line no-empty-pattern
      [INLINES.HYPERLINK]: ({}, children) => {
        if (children && children.length && children[0][1] !== ``) {
          return (
            <a href={`mailto:${data.contentfulBlogPost.author.emailAddress}`}>
              {children}
            </a>
          );
        }
        return <></>;
      },
    },
  };

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    const link = event.currentTarget.href;
    const target = event.currentTarget.target || `_self`;

    if (link.includes(`#`)) {
      const url = link.split(`#`);
      const targetElement = document.getElementById(url[1]);

      if (target) {
        event.preventDefault();
        event.stopPropagation();
        targetElement.scrollIntoView({
          behavior: `smooth`,
        });
      }
    }
  };

  const overrideContent = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
        if (!uri.includes(`youtu.be`)) {
          return (
            <Link
              className="link"
              to={uri}
              aria-label={`View ${children}`}
              target={uri.includes(`goo.gl/maps`) ? `_blank` : ``}
              onClick={handleLinkClick}
            >
              {children}
            </Link>
          );
        }

        const match =
          /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(
            uri,
          );

        const videoId = match && match[2].length === 11 ? match[2] : null;

        return (
          videoId && (
            <section className="video-container">
              <iframe
                className="video"
                title={`https://youtube.com/embed/${videoId}`}
                src={`https://youtube.com/embed/${videoId}`}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              />
            </section>
          )
        );
      },
    },
  };

  const articleType = data.contentfulBlogPost.type;

  return articleType === `event`
    ? Event({ data, override, location, overrideContent })
    : Article({
        data,
        override,
        overrideContent,
        location,
      });
};

export const query = graphql`
  query Insight($id: String) {
    contentfulBlogPost(id: { eq: $id }) {
      slug
      title
      subtitle
      category
      type
      registerLink
      registerLabel
      hidden
      heroVideo {
        file {
          url
        }
      }
      introVideo {
        file {
          url
        }
      }
      author {
        photo {
          gatsbyImageData
        }
        firstName
        lastName
        designation
        emailAddress
        calendlyUrl
        isActive
      }
      content {
        raw
        references {
          gatsbyImageData
          description
          contentfulId: contentful_id
          title
        }
      }
      introduction {
        raw
      }
      accordionExpanded
      speakerContents {
        title
        speakerPhotos {
          title
          description
          gatsbyImageData
        }
        content {
          raw
        }
      }
      eventDetails {
        title
        content {
          raw
        }
      }
      eventInfo {
        title
        content {
          raw
        }
        image {
          gatsbyImageData
        }
      }
      reserveCta {
        title
        content {
          raw
        }
        image {
          gatsbyImageData
        }
      }
      location {
        title
        content {
          raw
        }
        image {
          gatsbyImageData
        }
      }
      sponsorTitle
      logos {
        image {
          gatsbyImageData
        }
      }
      contactMessage {
        raw
      }
      excerpt {
        excerpt
      }
      createdAt
      image {
        gatsbyImageData
      }
      whitepaper {
        title
        slug
        image {
          image {
            gatsbyImageData
          }
        }
        file {
          file {
            url
            contentType
            fileName
            details {
              size
            }
          }
        }
      }
      category
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
      careersCta
      bookIntroCta
    }
  }
`;

export default ContentfulBlog;
